import _common from "./common.js";
import _mat from "./mat2.js";
import _mat2d from "./mat2d.js";
import _mat2 from "./mat3.js";
import _mat3 from "./mat4.js";
import _quat from "./quat.js";
import _quat2 from "./quat2.js";
import _vec from "./vec2.js";
import _vec2 from "./vec3.js";
import _vec3 from "./vec4.js";
var exports = {};

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.vec4 = exports.vec3 = exports.vec2 = exports.quat2 = exports.quat = exports.mat4 = exports.mat3 = exports.mat2d = exports.mat2 = exports.glMatrix = void 0;

var glMatrix = _interopRequireWildcard(_common);

exports.glMatrix = glMatrix;

var mat2 = _interopRequireWildcard(_mat);

exports.mat2 = mat2;

var mat2d = _interopRequireWildcard(_mat2d);

exports.mat2d = mat2d;

var mat3 = _interopRequireWildcard(_mat2);

exports.mat3 = mat3;

var mat4 = _interopRequireWildcard(_mat3);

exports.mat4 = mat4;

var quat = _interopRequireWildcard(_quat);

exports.quat = quat;

var quat2 = _interopRequireWildcard(_quat2);

exports.quat2 = quat2;

var vec2 = _interopRequireWildcard(_vec);

exports.vec2 = vec2;

var vec3 = _interopRequireWildcard(_vec2);

exports.vec3 = vec3;

var vec4 = _interopRequireWildcard(_vec3);

exports.vec4 = vec4;

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

export default exports;
export const __esModule = exports.__esModule;
const _vec4 = exports.vec4,
      _vec5 = exports.vec3,
      _vec6 = exports.vec2,
      _quat3 = exports.quat2,
      _quat4 = exports.quat,
      _mat4 = exports.mat4,
      _mat5 = exports.mat3,
      _mat2d2 = exports.mat2d,
      _mat6 = exports.mat2,
      _glMatrix = exports.glMatrix;
export { _vec4 as vec4, _vec5 as vec3, _vec6 as vec2, _quat3 as quat2, _quat4 as quat, _mat4 as mat4, _mat5 as mat3, _mat2d2 as mat2d, _mat6 as mat2, _glMatrix as glMatrix };